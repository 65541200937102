import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Header from "../Header";
import JournalEntry from "./JournalEntry";
import * as config from "../../config";
import { useAuth0 } from "@auth0/auth0-react";

function Journal() {
  const [entries, setEntries] = useState([]);
  const currentWeek = moment().week();
  const displayedWeek = parseInt(useParams().semana) || currentWeek;
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const [dataFetched, setDataFetched] = useState(false);
  const [token, setToken] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setEntries([]);

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${config.AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        },
      });
      setToken(accessToken);

      const response = await axios.get(
        `${config.API_BASE_URL}/journal-entries`,
        {
          params: {
            from: moment()
              .year(2023)
              .week(displayedWeek)
              .startOf("week"),
            to: moment()
              .year(2023)
              .week(displayedWeek)
              .endOf("week"),
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setEntries(response.data);
      setDataFetched(true);
    };

    if (!isLoading) {
      fetchData().catch(() => {
        window.location = "/";
      });
    }
  }, [isLoading, displayedWeek]);

  return (
    <div>
      <Header color="dark" />
      <div className="hero is-dark">
        <div className="hero-body">
          <div className="container">
            <p className="title">📆 Diário</p>
            <div className="mb-0">
              <a href="/cadernos" className="button is-small is-dark">
                <span className="icon">
                  <i className="fa-solid fa-arrow-left"></i>
                </span>
                <span>Cadernos</span>
              </a>
              <a className="button is-small is-dark" disabled>
                <span className="icon">
                  <i className="fa-solid fa-pen-to-square"></i>
                </span>
                <span>Editar</span>
              </a>
              <a className="button is-small is-dark" disabled>
                <span className="icon">
                  <i className="fa-solid fa-trash"></i>
                </span>
                <span>Apagar</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <progress
        className="progress square is-small is-dark"
        max="100"
        value={dataFetched ? "0" : null}
      ></progress>
      {dataFetched && (
        <section className="section without-padding-top">
          <div className="container">
            <h2 className="title is-4 mt-5">Semana {displayedWeek}</h2>
            <form className="block">
              {entries &&
                displayedWeek === currentWeek &&
                entries.filter((entry) =>
                  moment(entry.date).isSame(moment(), "day")
                ).length === 0 && (
                  <JournalEntry date={moment()} content={""} token={token} />
                )}

              {entries &&
                entries.length > 0 &&
                entries.map(
                  (entry) =>
                    entry && (
                      <JournalEntry
                        _id={entry._id}
                        date={moment(entry.date)}
                        content={entry.content}
                        token={token}
                      />
                    )
                )}

              {displayedWeek !== currentWeek &&
                entries &&
                entries.length === 0 && (
                  <article className="message is-light">
                    <div className="message-body">
                      Não há registros desta semana em seu diário.
                    </div>
                  </article>
                )}
            </form>
            <div className="block">
              <nav
                className="pagination is-right"
                role="navigation"
                aria-label="pagination"
              >
                <a
                  className={`pagination-previous ${displayedWeek === 1 &&
                    "is-disabled"}`}
                  href={`/cadernos/diario/${displayedWeek - 1}`}
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </a>
                <a
                  className={`pagination-next ${displayedWeek === currentWeek &&
                    "is-disabled"}`}
                  href={`/cadernos/diario/${displayedWeek + 1}`}
                >
                  <i className="fa-solid fa-arrow-right"></i>
                </a>
                <ul className="pagination-list">
                  {[
                    ...(currentWeek <= 5
                      ? [...Array(currentWeek).keys()].map((i) => i + 1)
                      : displayedWeek < 4
                      ? [1, 2, 3, 4, currentWeek]
                      : displayedWeek < currentWeek - 1
                      ? [
                          displayedWeek - 1,
                          displayedWeek,
                          displayedWeek + 1,
                          1,
                          currentWeek,
                        ]
                      : [
                          currentWeek - 3,
                          currentWeek - 2,
                          currentWeek - 1,
                          currentWeek,
                          1,
                        ]
                    ).sort((a, b) => a - b),
                  ].reduce((acc, weekNum, i, arr) => {
                    if (i > 0 && weekNum - arr[i - 1] > 1) {
                      acc.push(
                        <li key={`ellipsis-${i}`}>
                          <span className="pagination-ellipsis">&hellip;</span>
                        </li>
                      );
                    }
                    acc.push(
                      <li key={weekNum}>
                        <a
                          className={`pagination-link ${
                            weekNum === displayedWeek ? "is-current" : ""
                          }`}
                          href={`/cadernos/diario/${weekNum}`}
                        >
                          {weekNum}
                        </a>
                      </li>
                    );
                    return acc;
                  }, [])}
                </ul>
              </nav>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Journal;
