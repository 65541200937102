import React, { useState } from "react";
import axios from "axios";
import * as config from "../../config";

function SharingModal(props) {
  const [emailToBeWhitelisted, setEmailToBeWhitelisted] = useState("");
  const [whitelistedEmails, setWhitelistedEmails] = useState(
    props.page.whitelist
  );
  const [errorMessage, setErrorMessage] = useState("");

  const pagesURL = `${config.API_BASE_URL}/pages`;

  const addEmailToWhitelist = async () => {
    try {
      const response = await axios.put(
        `${pagesURL}/${props.page._id}/whitelist`,
        { whitelist: whitelistedEmails.concat(emailToBeWhitelisted) },
        { headers: { Authorization: `Bearer ${props.accessToken}` } }
      );

      setErrorMessage("");
      setEmailToBeWhitelisted("");
      setWhitelistedEmails(response.data.whitelist);
    } catch {
      setErrorMessage(
        "Erro inesperado. Espere alguns segundos e tente novamente."
      );
    }
  };

  const removeFromWhitelist = async (email) => {
    try {
      const response = await axios.put(
        `${pagesURL}/${props.page._id}/whitelist`,
        {
          whitelist: whitelistedEmails.filter(
            (whitelistedEmail) => email !== whitelistedEmail
          ),
        },
        { headers: { Authorization: `Bearer ${props.accessToken}` } }
      );

      setErrorMessage("");
      setEmailToBeWhitelisted("");
      setWhitelistedEmails(response.data.whitelist);
    } catch {
      setErrorMessage(
        "Erro inesperado. Espere alguns segundos e tente novamente."
      );
    }
  };

  return (
    <div className={`modal ${props.isActive && "is-active"}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Compartilhamento</p>
          <button
            className="delete"
            aria-label="close"
            onClick={props.onClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label">Quem deve ganhar acesso?</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Digite um endereço de e-mail"
                value={emailToBeWhitelisted}
                onChange={(e) => setEmailToBeWhitelisted(e.currentTarget.value)}
              />
            </div>
          </div>

          <div className="field">
            <button
              className="button is-dark"
              onClick={addEmailToWhitelist}
              disabled={emailToBeWhitelisted.length === 0}
            >
              <span className="icon is-small">
                <i className="fas fa-check"></i>
              </span>
              <span>Confirmar</span>
            </button>
          </div>

          {errorMessage && (
            <div className="notification is-danger is-light">
              <button
                className="delete"
                onClick={() => setErrorMessage("")}
              ></button>
              {errorMessage}
            </div>
          )}

          {whitelistedEmails.length > 0 && (
            <div>
              <hr />
              <div className="field">
                <label className="label">Quem já tem acesso?</label>
                <div className="control">
                  <div className="field is-grouped is-grouped-multiline">
                    {whitelistedEmails.map((whitelistedEmail) => (
                      <div className="control">
                        <div className="tags has-addons">
                          <a className="tag is-light">{whitelistedEmail}</a>
                          <a
                            className="tag is-delete"
                            onClick={() =>
                              removeFromWhitelist(whitelistedEmail)
                            }
                          ></a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        <footer className="modal-card-foot"></footer>
      </div>
    </div>
  );
}

export default SharingModal;
