const renderLinks = (text) => {
  const urlsRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])(?![">])/gim;
  return text.replace(
    urlsRegex,
    '<a href="$1" target="_blank" rel="noopener noreferrer nofollow"><u>$1</u></a>'
  );
};

const renderImages = (text) => {
  const imagesRegex = /(https?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*\.(jpeg|jpg|png|gif)(?![">]))/gim;
  return text.replace(
    imagesRegex,
    '<img src="$1" style="width: 100%; max-width: 500px;" />'
  );
};

const isTeacher = (user) => {
  return user["https://eutimia.cc/roles"].includes("Teacher");
};

export { renderLinks, renderImages, isTeacher };
