import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "../Header";
import Question from "./Question";
import * as config from "../../config";
import axios from "axios";
import { useParams } from "react-router-dom";
import { isTeacher } from "../helperFunctions";

function Quiz(props) {
  const { isLoading, getAccessTokenSilently, user } = useAuth0();
  const [dataFetched, setDataFetched] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [token, setToken] = useState("");
  const { id } = useParams();
  const [quiz, setQuiz] = useState({});
  const [questions, setQuestions] = useState([]);
  const [propertiesVisible, setPropertiesVisible] = useState(false);
  const [questionsVisible, setQuestionsVisible] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const quizzesURL = `${config.QUIZZES_API_BASE_URL}/quiz`;
  const questionsURL = `${config.QUIZZES_API_BASE_URL}/question`;

  const submit = () => {
    document.documentElement.scroll(0, 0);
    setSubmitted(true);
  };

  const togglePropertiesVisibility = () => {
    setPropertiesVisible(!propertiesVisible);
  };

  const toggleQuestionsVisibility = () => {
    setQuestionsVisible(!questionsVisible);
  };

  const addQuestion = (e) => {
    e.preventDefault();

    const newQuestion = {
      text: "Enunciado",
      type: "single",
      options: [
        { option: "Alternativa 1", correct: true },
        { option: "Alternativa 2", correct: false },
      ],
    };

    setQuestions([...questions, newQuestion]);
  };

  const removeOption = (questionIndex, optionText) => {
    const updatedQuestions = questions.map((question, i) => {
      const updatedOptions = question.options.filter(
        (option) => option.option !== optionText
      );

      const updatedType =
        updatedOptions.filter((option) => option.correct).length > 1
          ? "multiple"
          : "single";

      if (i === questionIndex) {
        return {
          ...question,
          options: updatedOptions,
          type: updatedType,
        };
      } else {
        return question;
      }
    });

    setQuestions(updatedQuestions);
  };

  const saveQuestions = async () => {
    try {
      let questionIds = [];

      for (let question of questions) {
        const payload = {
          text: question.text,
          type: question.type,
          options: question.options,
        };

        const headers = {
          headers: { Authorization: `Bearer ${token}` },
        };

        const response = question.id
          ? await axios.put(`${questionsURL}/${question.id}/`, payload, headers)
          : await axios.post(`${questionsURL}/`, payload, headers);

        questionIds = [...questionIds, response.data.id];
      }

      return questionIds;
    } catch (e) {
      console.log(e);
    }
  };

  const saveQuiz = async () => {
    if (quiz.title === "") {
      setErrorMessage("O título do questionário não pode estar vazio");
    } else if (
      questions.filter((question) => question.options.length < 2).length > 0
    ) {
      setErrorMessage(
        "Todas as questões precisam ter pelo menos duas alternativas"
      );
    } else if (
      questions.filter(
        (question) =>
          question.options.filter((option) => option.correct).length === 0
      ).length > 0
    ) {
      setErrorMessage(
        "Todas as questões precisam ter pelo menos uma alternativa correta"
      );
    } else if (
      questions.filter((question) => question.text === "").length > 0
    ) {
      setErrorMessage("Todas as questões precisam ter um enunciado");
    } else if (
      questions.filter(
        (question) =>
          question.options.filter((option) => option.option === "").length > 0
      ).length > 0
    ) {
      setErrorMessage(
        "Nenhuma questão pode ter alternativas duplicadas ou em branco"
      );
    } else {
      const questionIds = await saveQuestions();

      await axios.put(
        `${quizzesURL}/${quiz.id}/`,
        {
          title: quiz.title,
          color: quiz.color,
          question_ids: questionIds,
          min_score: quiz.min_socre,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      window.location = `/questionarios/${quiz.id}${
        questionIds.length === 0 ? "/editar" : ""
      }`;
    }
  };

  const deleteQuiz = async (e) => {
    e.preventDefault();

    await axios.delete(`${quizzesURL}/${quiz.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    window.location = `/questionarios`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${config.AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        },
      });
      setToken(accessToken);

      const response = await axios.get(`${quizzesURL}/${id}/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setQuiz(response.data);

      const questionIds = response.data.question_ids;
      const questionsData = [];

      for (const id of questionIds) {
        const response = await axios.get(`${questionsURL}/${id}/`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        const data = response.data;
        questionsData.push(data);
      }
      setQuestions(questionsData);

      console.log(questionsData);

      setDataFetched(true);
    };

    if (!isLoading) {
      fetchData().catch(() => {
        window.location = "/";
      });
    }
  }, [isLoading]);

  return quiz.color ? (
    <div>
      <Header color={quiz.color} />
      <div className={`hero is-${quiz.color}`}>
        <div className="hero-body">
          <div className="container">
            <p className="title">{quiz.title}</p>
            <div className="mb-0">
              <a
                href="/questionarios"
                className={`button is-small is-${quiz.color}`}
              >
                <span className="icon">
                  <i className="fa-solid fa-arrow-left"></i>
                </span>
                <span>Questionários</span>
              </a>
              {props.editing ? (
                <button
                  onClick={() =>
                    (window.location = `/questionarios/${quiz.id}`)
                  }
                  className={`button is-small is-${quiz.color}`}
                  disabled={
                    questions.length === 0 ||
                    (questions.length > 0 && !questions[0].id)
                  }
                >
                  <span className="icon">
                    <i className="fa-solid fa-lightbulb"></i>
                  </span>
                  <span>
                    {isTeacher(user) ? "Visão do aluno" : "Responder"}
                  </span>
                </button>
              ) : (
                <a
                  href={`/questionarios/${quiz.id}/editar`}
                  className={`button is-small is-${quiz.color}`}
                >
                  <span className="icon">
                    <i className="fa-solid fa-pen-to-square"></i>
                  </span>
                  <span>Editar</span>
                </a>
              )}
              <a
                className={`button is-small is-${quiz.color}`}
                onClick={(e) => deleteQuiz(e)}
              >
                <span className="icon">
                  <i className="fa-solid fa-trash"></i>
                </span>
                <span>Apagar</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <progress
        className={`progress square is-small is-${quiz.color}`}
        max="100"
        value={dataFetched ? "0" : null}
      ></progress>
      {dataFetched && (
        <section className="section without-padding-top">
          {props.editing && (
            <div className="container mt-5">
              <h2 className="title is-5" onClick={togglePropertiesVisibility}>
                <span className="icon-text">
                  <span className="icon">
                    <i
                      className={`fas fa-chevron-${
                        propertiesVisible ? "down" : "right"
                      }`}
                    ></i>
                  </span>
                  <span>Propriedades do questionário</span>
                </span>
              </h2>
              {propertiesVisible && (
                <div className="fields">
                  <div className="field">
                    <label className="label">Cor</label>
                    <div className="control">
                      <div className="buttons">
                        <button
                          className={`button is-info ${quiz.color !== "info" &&
                            "is-light"}`}
                          onClick={() => setQuiz({ ...quiz, color: "info" })}
                        >
                          <span className="icon is-small">
                            <i
                              className={`fa-regular ${
                                quiz.color === "info"
                                  ? "fa-circle-dot"
                                  : "fa-circle"
                              }`}
                            ></i>
                          </span>
                          <span>Azul</span>
                        </button>
                        <button
                          className={`button is-success ${quiz.color !==
                            "success" && "is-light"}`}
                          onClick={() => setQuiz({ ...quiz, color: "success" })}
                        >
                          <span className="icon is-small">
                            <i
                              className={`fa-regular ${
                                quiz.color === "success"
                                  ? "fa-circle-dot"
                                  : "fa-circle"
                              }`}
                            ></i>
                          </span>
                          <span>Verde</span>
                        </button>
                        <button
                          className={`button is-warning ${quiz.color !==
                            "warning" && "is-light"}`}
                          onClick={() => setQuiz({ ...quiz, color: "warning" })}
                        >
                          <span className="icon is-small">
                            <i
                              className={`fa-regular ${
                                quiz.color === "warning"
                                  ? "fa-circle-dot"
                                  : "fa-circle"
                              }`}
                            ></i>
                          </span>
                          <span>Amarelo</span>
                        </button>
                        <button
                          className={`button is-danger ${quiz.color !==
                            "danger" && "is-light"}`}
                          onClick={() => setQuiz({ ...quiz, color: "danger" })}
                        >
                          <span className="icon is-small">
                            <i
                              className={`fa-regular ${
                                quiz.color === "danger"
                                  ? "fa-circle-dot"
                                  : "fa-circle"
                              }`}
                            ></i>
                          </span>
                          <span>Vermelho</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Título</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        value={quiz.title}
                        onChange={(e) =>
                          setQuiz({ ...quiz, title: e.target.value })
                        }
                        maxLength="22"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="container mt-5">
            {props.editing && <hr />}

            <h2
              className="title is-5 mt-5"
              onClick={props.editing && toggleQuestionsVisibility}
            >
              {props.editing ? (
                <span className="icon-text">
                  <span className="icon">
                    <i
                      className={`fas fa-chevron-${
                        questionsVisible ? "down" : "right"
                      }`}
                    ></i>
                  </span>
                  <span>Enunciados e respostas</span>
                </span>
              ) : submitted ? (
                "Questionário finalizado"
              ) : (
                "Responda às questões a seguir"
              )}
            </h2>

            {questionsVisible && (
              <div>
                {questions.length > 0
                  ? questions.map((question, i) => (
                      <Question
                        quiz={quiz}
                        id={question.id}
                        title={question.text}
                        type={question.type}
                        options={question.options}
                        token={token}
                        submitted={submitted}
                        editing={props.editing}
                        index={i}
                        key={question.id}
                        questions={questions}
                        setQuestions={setQuestions}
                        removeOption={(optionText) =>
                          removeOption(i, optionText)
                        }
                        setErrorMessage={setErrorMessage}
                      />
                    ))
                  : !props.editing && (
                      <article className="message is-light">
                        <div className="message-body">
                          Ainda não há nenhuma pergunta neste questionário.
                          Clique em "Editar" para mudar isso agora 😉
                        </div>
                      </article>
                    )}

                {props.editing ? (
                  <div>
                    <button className="button" onClick={addQuestion}>
                      <span className="icon">
                        <i className="fa-solid fa-plus"></i>
                      </span>
                      <span>
                        {questions.length > 0
                          ? "Adicionar mais uma questão"
                          : "Criar primeira questão"}
                      </span>
                    </button>{" "}
                  </div>
                ) : (
                  questions.length > 0 && (
                    <button
                      className="button is-dark"
                      onClick={() =>
                        submitted ? window.location.reload() : submit()
                      }
                      disabled={
                        questions.filter((question) => !question.answered)
                          .length > 0
                      }
                    >
                      <span className="icon">
                        <i
                          className={`fa-solid fa-${
                            submitted ? "arrows-rotate" : "check"
                          }`}
                        ></i>
                      </span>
                      <span>
                        {submitted ? "Recomeçar" : "Validar respostas"}
                      </span>
                    </button>
                  )
                )}
              </div>
            )}

            {errorMessage ? (
              <div className="field notification is-danger is-light mt-3">
                <button
                  className="delete"
                  onClick={() => setErrorMessage("")}
                ></button>
                {errorMessage}
              </div>
            ) : (
              props.editing && <hr />
            )}

            {props.editing && (
              <div className="buttons">
                <button className="button is-dark" onClick={saveQuiz}>
                  <span className="icon is-small">
                    <i className="fas fa-check"></i>
                  </span>
                  <span>Salvar questionário</span>
                </button>
                <button
                  className="button"
                  onClick={() =>
                    (window.location = `/questionarios/${quiz.id}`)
                  }
                >
                  <span className="icon is-small">
                    <i className="fa-solid fa-xmark"></i>
                  </span>
                  <span>Cancelar</span>
                </button>
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  ) : (
    <div></div>
  );
}

export default Quiz;
