import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as config from "../config";

function Home() {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const { loginWithRedirect } = useAuth0();

  return (
    <div>
      <div className="hero is-medium is-primary is-bold">
        <div className="hero-head">
          <nav
            className="navbar is-primary is-bold"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="container">
              <div className="navbar-brand">
                <span className="navbar-item">
                  <b>EUTIMIA</b>
                </span>
                <a
                  href="/"
                  role="button"
                  className={`navbar-burger ${mobileMenuVisible &&
                    "is-active"}`}
                  aria-label="menu"
                  aria-expanded="false"
                  onClick={(e) => {
                    e.preventDefault();
                    setMobileMenuVisible(!mobileMenuVisible);
                  }}
                >
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </a>
              </div>
              <div
                className={`navbar-menu ${mobileMenuVisible && "is-active"}`}
              >
                <div className="navbar-start">
                  <a className="navbar-item" href="#estudantes">
                    Estudantes
                  </a>
                  <a className="navbar-item" href="#professores">
                    Professores
                  </a>
                  <a className="navbar-item" href="#instituicoes">
                    Instituições
                  </a>
                  <a
                    className="navbar-item"
                    href={config.INSTAGRAM_LINK}
                    target="_blank"
                  >
                    <span className="icon">
                      <i className="fa-brands fa-instagram"></i>
                    </span>
                    <span>Blog</span>
                  </a>
                  <a
                    className="navbar-item"
                    href={config.CONTACT_FORM_LINK}
                    target="_blank"
                  >
                    Contato
                  </a>
                </div>
                <div className="navbar-end">
                  <div className="navbar-item">
                    <div className="buttons">
                      <button
                        className="button is-white"
                        onClick={() => loginWithRedirect()}
                      >
                        Acesso à plataforma
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="hero-body">
          <div className="container">
            <p className="title">Ciência e tecnologia aplicadas à educação.</p>
            <p className="subtitle">
              A Eutimia é uma solução completa para estudantes, professores e
              instituições que acreditam que a educação não se limita a testes e
              avaliações, embora reconheçam a importância destes na vida real.
            </p>
          </div>
        </div>
      </div>
      <div id="estudantes" className="hero is-medium">
        <div className="hero-body">
          <div className="container">
            <p className="subtitle is-4">Para estudantes 🧑‍🎓</p>
            <br />
            <p className="title">Estude menos e lembre mais. Simples assim.</p>
            <p className="subtitle">
              Tudo o que você aprende através da nossa plataforma é mostrado
              novamente em datas estratégicas, graças ao nosso algoritmo de
              revisão inteligente. Assim,{" "}
              <span className="tag is-warning is-medium">
                você não precisa estudar todos os dias, mas somente quando
                estiver quase esquecendo daquilo que aprendeu
              </span>
              .<br />
              <br />
              <a className="is-underlined" onClick={() => loginWithRedirect()}>
                Faça seu cadastro grátis
              </a>
            </p>
          </div>
        </div>
      </div>
      <div id="professores" className="hero is-medium is-warning is-bold">
        <div className="hero-body">
          <div className="container">
            <p className="subtitle is-4">Para professores 🧑‍🏫</p>
            <br />
            <p className="title">
              Crie e compartilhe materiais didáticos surpreendentes...
            </p>
            <p className="subtitle">
              Na nossa plataforma, você pode criar quantos <b>textos</b>,{" "}
              <b>cadernos</b>, <b>coleções de flashcards</b> e{" "}
              <b>questionários</b> desejar, além de disponibilizá-los a seus
              alunos em um piscar de olhos. Nós garantimos que eles irão amar,
              pois verão que,{" "}
              <span className="tag is-medium is-light">
                estudando de forma eficiente, precisam se esforçar muito menos
              </span>
              .
            </p>
            <br />
            <p className="title has-text-right">
              ... e saiba com o quê seus alunos têm mais dificuldade!
            </p>
            <p className="subtitle has-text-right">
              Nossos gráficos e relatórios vão ajudar você a entender o que está
              dando certo e o que ainda pode melhorar em seu material.
            </p>
            <br />
            <p className="subtitle">
              <a
                href={config.CONTACT_FORM_LINK}
                target="_blank"
                className="is-underlined"
              >
                Entre em contato conosco
              </a>
            </p>
          </div>
        </div>
      </div>
      <div id="instituicoes" className="hero is-medium is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            <p className="subtitle is-4">Para instituições 🏫</p>
            <br />
            <p className="title">
              Não deixe que seus alunos e professores fiquem de fora dessa.
            </p>
            <p className="subtitle">
              Temos um plano exclusivo para escolas e universidades, garantindo
              um desconto especial na assinatura de múltiplos acessos.
              <br />
              <br />
              <a
                href={config.CONTACT_FORM_LINK}
                target="_blank"
                className="is-underlined"
              >
                Entre em contato conosco
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
