import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import * as config from "../../config";
import Header from "../Header";
import { useParams } from "react-router-dom";
import moment from "moment";
import { isTeacher } from "../helperFunctions";

function DeckForm(props) {
  const [color, setColor] = useState("light");
  const [title, setTitle] = useState("");
  const [flashcardsToReview, setFlashcardsToReview] = useState(0);
  const { isLoading, getAccessTokenSilently, user } = useAuth0();
  const [dataFetched, setDataFetched] = useState(false);
  const [token, setToken] = useState("");
  const targetURL = `${config.API_BASE_URL}/decks`;
  const [errorMessage, setErrorMessage] = useState("");
  const { deckId } = useParams();
  const [propertiesVisible, setPropertiesVisible] = useState(!props.editing);
  const [flashcardsVisible, setFlashcardsVisible] = useState(props.editing);
  const [flashcards, setFlashcards] = useState([]);
  const [creatingFlashcard, setCreatingFlashcard] = useState(true);
  const [editingFlashcard, setEditingFlashcard] = useState(false);
  const [flashcardId, setFlashcardId] = useState("");
  const [frontsideTextarea, setFrontsideTextarea] = useState("");
  const [backsideTextarea, setBacksideTextarea] = useState("");
  const [saved, setSaved] = useState(false);
  const flashcardsURL = `${config.API_BASE_URL}/flashcards`;

  const togglePropertiesVisibility = () => {
    setPropertiesVisible(!propertiesVisible);
  };

  const toggleFlashcardsVisibility = () => {
    setFlashcardsVisible(!flashcardsVisible);
  };

  const deleteDeck = async () => {
    try {
      await axios.delete(`${targetURL}/${deckId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      window.location = `/decks`;
    } catch (e) {
      setErrorMessage(
        "Não conseguimos apagar este deck por conta de um erro inesperado. Espere alguns segundos e tente novamente."
      );
    }
  };

  const deleteFlashcard = async (e, _id) => {
    e.preventDefault();

    setSaved(false);
    try {
      await axios.delete(`${flashcardsURL}/${_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setErrorMessage("");
      setSaved(true);
    } catch (e) {
      setErrorMessage(
        "Não conseguimos apagar este flashcard por conta de um erro inesperado. Espere alguns segundos e tente novamente."
      );
    }
  };

  const limitCharacters = (text) => {
    return text.length > 30 ? text.slice(0, 30).concat(" [...]") : text;
  };

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${config.AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        },
      });

      setToken(accessToken);

      if (deckId) {
        const deckData = (
          await axios.get(`${targetURL}/${deckId}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
        ).data;

        setTitle(deckData.title);
        setColor(deckData.color);
        setFlashcardsToReview(deckData.flashcardsToReview);

        const flashcardsData = (
          await axios.get(`${flashcardsURL}?deckId=${deckId}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
        ).data;

        setFlashcards(flashcardsData);
      }

      setDataFetched(true);
    };

    fetchData();
  }, [isLoading, saved]);

  const saveHandler = async (e) => {
    e.preventDefault();

    if (color && title) {
      try {
        if (deckId) {
          await axios.put(
            `${targetURL}/${deckId}`,
            {
              color,
              title,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          window.location = "/decks";
        } else {
          await axios.post(
            targetURL,
            {
              color,
              title,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          window.location = "/decks";
        }
      } catch (e) {
        setErrorMessage(
          "Não conseguimos salvar o seu deck por conta de um erro inesperado. Espere alguns segundos e tente novamente."
        );
      }
    } else {
      setErrorMessage(
        "Você precisa preencher todos os campos acima para salvar este deck."
      );
    }
  };

  const saveFlashcard = async (e) => {
    e.preventDefault();

    setSaved(false);
    if (frontsideTextarea && backsideTextarea) {
      try {
        if (editingFlashcard) {
          await axios.put(
            flashcardsURL,
            {
              _id: flashcardId,
              front: frontsideTextarea,
              back: backsideTextarea,
              deckId,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        } else {
          await axios.post(
            flashcardsURL,
            {
              front: frontsideTextarea,
              back: backsideTextarea,
              deckId,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        }
        setEditingFlashcard(false);
        setSaved(true);
        setErrorMessage("");
        setFrontsideTextarea("");
        setBacksideTextarea("");
      } catch (e) {
        setErrorMessage(
          "Não conseguimos salvar o seu registro por conta de um erro inesperado. Espere alguns segundos e tente novamente."
        );
      }
    } else {
      setErrorMessage(
        "Você precisa preencher todos os campos acima para salvar este flashcard."
      );
    }
  };

  return (
    <div>
      <Header color={deckId || color ? color : "light"} />
      <div className={`hero is-${deckId || color ? color : "light"}`}>
        <div className="hero-body">
          <div className="container">
            <p className="title">{deckId || title ? title : "Novo deck"}</p>
            {dataFetched && props.editing && (
              <div className="mb-0">
                <a href="/decks" className={`button is-small is-${color}`}>
                  <span className="icon">
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                  <span>Decks</span>
                </a>
                <a
                  href={
                    (isTeacher(user) && flashcards.length > 0) ||
                    flashcardsToReview > 0
                      ? `/decks/${deckId}`
                      : "javascript:void(0);"
                  }
                  className={`button is-small is-${color}`}
                  disabled={
                    (isTeacher(user) && flashcards.length === 0) ||
                    flashcardsToReview === 0
                  }
                >
                  <span className="icon">
                    <i className="fa-solid fa-pen-to-square"></i>
                  </span>
                  <span>
                    {isTeacher(user)
                      ? "Visão do aluno"
                      : `Revisar (${flashcardsToReview})`}
                  </span>
                </a>
                <a
                  className={`button is-small is-${color}`}
                  onClick={deleteDeck}
                >
                  <span className="icon">
                    <i className="fa-solid fa-trash"></i>
                  </span>
                  <span>Apagar</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <progress
        className={`progress square is-small is-${color || "light"}`}
        max="100"
        value={dataFetched ? "0" : null}
      ></progress>
      {dataFetched && (
        <section className="section without-padding-top">
          <div className="container">
            <h2
              className="title is-5"
              onClick={props.editing && togglePropertiesVisibility}
            >
              <span className="icon-text">
                {props.editing && (
                  <span className="icon">
                    <i
                      className={`fas fa-chevron-${
                        propertiesVisible ? "down" : "right"
                      }`}
                    ></i>
                  </span>
                )}
                <span>Propriedades</span>
              </span>
            </h2>
            {propertiesVisible && (
              <div className="fields">
                <div className="field">
                  <label className="label">Cor</label>
                  <div className="control">
                    <div className="buttons">
                      <button
                        className={`button is-info ${color !== "info" &&
                          "is-light"}`}
                        onClick={() => setColor("info")}
                      >
                        <span className="icon is-small">
                          <i
                            className={`fa-regular ${
                              color === "info" ? "fa-circle-dot" : "fa-circle"
                            }`}
                          ></i>
                        </span>
                        <span>Azul</span>
                      </button>
                      <button
                        className={`button is-success ${color !== "success" &&
                          "is-light"}`}
                        onClick={() => setColor("success")}
                      >
                        <span className="icon is-small">
                          <i
                            className={`fa-regular ${
                              color === "success"
                                ? "fa-circle-dot"
                                : "fa-circle"
                            }`}
                          ></i>
                        </span>
                        <span>Verde</span>
                      </button>
                      <button
                        className={`button is-warning ${color !== "warning" &&
                          "is-light"}`}
                        onClick={() => setColor("warning")}
                      >
                        <span className="icon is-small">
                          <i
                            className={`fa-regular ${
                              color === "warning"
                                ? "fa-circle-dot"
                                : "fa-circle"
                            }`}
                          ></i>
                        </span>
                        <span>Amarelo</span>
                      </button>
                      <button
                        className={`button is-danger ${color !== "danger" &&
                          "is-light"}`}
                        onClick={() => setColor("danger")}
                      >
                        <span className="icon is-small">
                          <i
                            className={`fa-regular ${
                              color === "danger" ? "fa-circle-dot" : "fa-circle"
                            }`}
                          ></i>
                        </span>
                        <span>Vermelho</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label">Título</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      onChange={(e) => setTitle(e.currentTarget.value)}
                      maxLength="22"
                      value={title}
                    />
                  </div>
                </div>
                {errorMessage && (
                  <div className="field notification is-danger is-light">
                    <button
                      className="delete"
                      onClick={() => setErrorMessage("")}
                    ></button>
                    {errorMessage}
                  </div>
                )}
                <div className="buttons">
                  <button className="button is-dark" onClick={saveHandler}>
                    <span className="icon is-small">
                      <i className="fas fa-check"></i>
                    </span>
                    <span>Salvar</span>
                  </button>
                  <button
                    className="button"
                    onClick={() =>
                      props.editing
                        ? window.location.reload()
                        : (window.location = "/decks")
                    }
                  >
                    <span className="icon is-small">
                      <i className="fa-solid fa-xmark"></i>
                    </span>
                    <span>Cancelar</span>
                  </button>
                </div>
              </div>
            )}

            {props.editing && (
              <>
                <hr />
                <h2 className="title is-5" onClick={toggleFlashcardsVisibility}>
                  <span className="icon-text">
                    <span className="icon">
                      <i
                        className={`fas fa-chevron-${
                          flashcardsVisible ? "down" : "right"
                        }`}
                      ></i>
                    </span>
                    <span>Flashcards</span>
                  </span>
                </h2>
              </>
            )}
            {flashcardsVisible && (
              <div>
                {flashcards.length > 0 ? (
                  <div className="table-container">
                    <table className="table is-bordered is-fullwidth">
                      <thead>
                        <tr>
                          <th>Frente</th>
                          <th className="is-hidden-mobile">Verso</th>
                          {!isTeacher(user) && <th>Data ideal para revisão</th>}
                          <th>Opções</th>
                        </tr>
                      </thead>
                      <tbody>
                        {flashcards.map((flashcard) => (
                          <tr>
                            <td>{limitCharacters(flashcard.front)}</td>
                            <td className="is-hidden-mobile">
                              <span
                                className={`tag is-light ${!isTeacher(user) &&
                                  "has-text-light"}`}
                              >
                                {limitCharacters(flashcard.back)}
                              </span>
                            </td>
                            {!isTeacher(user) && (
                              <td>{moment(flashcard.dueDate).calendar()}</td>
                            )}
                            <td>
                              <div className="buttons">
                                <a
                                  href="#editar-flashcard"
                                  className="button is-small"
                                  onClick={() => {
                                    setCreatingFlashcard(false);
                                    setFlashcardId(flashcard._id);
                                    setEditingFlashcard(true);
                                    setFrontsideTextarea(flashcard.front);
                                    setBacksideTextarea(flashcard.back);
                                  }}
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>
                                </a>
                                <button
                                  className="button is-small"
                                  onClick={(e) => {
                                    deleteFlashcard(e, flashcard._id);
                                  }}
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="notification">
                    <p className="subtitle is-6">
                      Este deck ainda está vazio. Você pode mudar isso através
                      do formulário abaixo.
                    </p>
                  </div>
                )}

                <div>
                  <div className="fields">
                    <div className="field">
                      <label className="label">Frente</label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          placeholder="Escreva uma pergunta"
                          rows="3"
                          onChange={(e) =>
                            setFrontsideTextarea(e.currentTarget.value)
                          }
                          value={frontsideTextarea}
                        ></textarea>
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Verso</label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          placeholder="Escreva a resposta da pergunta acima"
                          rows="3"
                          onChange={(e) =>
                            setBacksideTextarea(e.currentTarget.value)
                          }
                          value={backsideTextarea}
                        ></textarea>
                      </div>
                    </div>
                    {errorMessage && (
                      <div className="field notification is-danger is-light">
                        <button
                          className="delete"
                          onClick={() => setErrorMessage("")}
                        ></button>
                        {errorMessage}
                      </div>
                    )}
                    <div className="buttons">
                      <button
                        className="button is-dark"
                        onClick={saveFlashcard}
                      >
                        <span className="icon is-small">
                          <i className="fas fa-check"></i>
                        </span>
                        <span>
                          {editingFlashcard
                            ? "Atualizar flashcard"
                            : "Criar flashcard"}
                        </span>
                      </button>
                      {(editingFlashcard ||
                        frontsideTextarea ||
                        backsideTextarea) && (
                        <button
                          className="button"
                          onClick={() => {
                            setFrontsideTextarea("");
                            setBacksideTextarea("");
                            setEditingFlashcard(false);
                          }}
                        >
                          <span className="icon is-small">
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                          <span>Cancelar</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
}

export default DeckForm;
