import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ContentEditable from "react-contenteditable";
import { sanitize, addHook } from "dompurify";
import * as config from "../../config";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "../Header";
import SharingModal from "./SharingModal";
import { renderLinks, renderImages, isTeacher } from "../helperFunctions";

// TODO: make this global
addHook("afterSanitizeAttributes", function(node) {
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener noreferrer nofollow");
  }
});

function Notebook() {
  const { notebookId, sectionId, pageId } = useParams();

  const [notebook, setNotebook] = useState({});

  const [currentSection, setCurrentSection] = useState({});
  const [creatingSection, setCreatingSection] = useState(false);
  const [newSectionTitle, setNewSectionTitle] = useState("");

  const [sections, setSections] = useState([]);
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState({});
  const [creatingPage, setCreatingPage] = useState(false);
  const [editingPage, setEditingPage] = useState(false);
  const [noteTitleInput, setNoteTitleInput] = useState("");
  const [noteContentInput, setNoteContentInput] = useState("");

  const [isSharingModalActive, setSharingModalActive] = useState(false);
  const openSharingModal = () => {
    setSharingModalActive(true);
  };
  const closeSharingModal = () => {
    setSharingModalActive(false);
  };

  const [warningMessage, setWarningMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { isLoading, getAccessTokenSilently, user } = useAuth0();
  const [dataFetched, setDataFetched] = useState(false);
  const [token, setToken] = useState("");

  const notebooksURL = `${config.API_BASE_URL}/notebooks`;
  const sectionsURL = `${config.API_BASE_URL}/sections`;
  const pagesURL = `${config.API_BASE_URL}/pages`;

  const isSharedView = window.location.pathname.startsWith("/s/");

  useEffect(() => {
    setNoteTitleInput(editingPage ? currentPage.title : "");
    setNoteContentInput(editingPage ? currentPage.content : "");

    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${config.AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        },
      });
      setToken(accessToken);

      setNotebook(
        (
          await axios.get(`${notebooksURL}/${notebookId}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
        ).data
      );

      const sectionsData = (
        await axios.get(`${sectionsURL}?notebookId=${notebookId}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
      ).data.sort((a, b) => a.title.localeCompare(b.title));

      setSections(sectionsData);

      if (sectionsData.length > 0 && sectionsData[0]._id) {
        const pagesData = (
          await axios.get(
            `${pagesURL}?sectionId=${sectionId || sectionsData[0]._id}`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          )
        ).data.sort((a, b) => a.title.localeCompare(b.title));

        setPages(pagesData);

        setCurrentSection(
          sectionId
            ? sectionsData.find((section) => section._id === sectionId)
            : sectionsData[0]
        );

        setCurrentPage(
          pageId ? pagesData.find((page) => page._id === pageId) : pagesData[0]
        );
      }

      setDataFetched(true);
    };

    if (!isLoading) {
      fetchData().catch((e) => {
        console.log(e);
      });
    }
  }, [isLoading, editingPage]);

  const saveHandler = async (e) => {
    e.preventDefault();

    try {
      let redirectionId = "";

      if (editingPage) {
        await axios.put(
          `${pagesURL}/${currentPage._id}`,
          {
            title: noteTitleInput,
            content: noteContentInput,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        redirectionId = currentPage._id;
      } else {
        const response = await axios.post(
          pagesURL,
          {
            title: noteTitleInput,
            content: noteContentInput,
            sectionId: currentSection._id,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        redirectionId = response.data._id;
      }

      window.location = `/cadernos/${notebookId}/secoes/${currentSection._id}/paginas/${redirectionId}`;
    } catch (e) {
      setErrorMessage(
        "Não conseguimos salvar esta página por conta de um erro inesperado. Espere alguns segundos e tente novamente."
      );
    }
  };

  const deleteHandler = async (e) => {
    e.preventDefault();

    try {
      await axios.delete(`${pagesURL}/${currentPage._id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      window.location = `/cadernos/${notebookId}`;
    } catch (e) {
      setErrorMessage(
        "Não conseguimos apagar esta página por conta de um erro inesperado. Espere alguns segundos e tente novamente."
      );
    }
  };

  const transformSelection = (e, command, color) => {
    e.preventDefault();

    if (command === "highlight") {
      const selection = document.getSelection().toString();
      const httpRegex = /\bhttps?:\/\/\S+/gim;

      if (selection && !httpRegex.test(selection)) {
        document.execCommand(
          "insertHTML",
          false,
          `<span class="tag is-${color} is-medium">${selection}</span>`
        );
      } else {
        setWarningMessage("Marcações não podem conter links ou imagens");
      }
    } else {
      document.execCommand(command);
    }
  };

  const saveSection = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        sectionsURL,
        { title: newSectionTitle, notebookId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      window.location = `/cadernos/${notebookId}/secoes/${response.data.section._id}/paginas/${response.data.page._id}`;
    } catch (e) {
      console.log(e);
      setErrorMessage(
        "Não conseguimos salvar esta seção por conta de um erro inesperado. Espere alguns segundos e tente novamente."
      );
    }
  };

  const deleteNotebook = async () => {
    try {
      await axios.delete(`${notebooksURL}/${notebookId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      window.location = `/cadernos`;
    } catch (e) {
      setErrorMessage(
        "Não conseguimos apagar este caderno por conta de um erro inesperado. Espere alguns segundos e tente novamente."
      );
    }
  };

  return notebook.color ? (
    <div>
      <Header color={notebook.color} />
      <div className={`hero is-${notebook.color}`}>
        <div className="hero-body">
          <div className="container">
            <p className="title">{notebook.title}</p>
            <div className="mb-0">
              <a
                href="/cadernos"
                className={`button is-small is-${notebook.color}`}
              >
                <span className="icon">
                  <i className="fa-solid fa-arrow-left"></i>
                </span>
                <span>Cadernos</span>
              </a>
              {!isSharedView && (
                <a
                  href={`/cadernos/${notebookId}/editar`}
                  className={`button is-small is-${notebook.color}`}
                >
                  <span className="icon">
                    <i className="fa-solid fa-pen-to-square"></i>
                  </span>
                  <span>Editar</span>
                </a>
              )}
              {!isSharedView && (
                <a
                  className={`button is-small is-${notebook.color}`}
                  onClick={deleteNotebook}
                >
                  <span className="icon">
                    <i className="fa-solid fa-trash"></i>
                  </span>
                  <span>Apagar</span>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <progress
        className={`progress square is-small is-${notebook.color}`}
        max="100"
        value={dataFetched ? "0" : null}
      ></progress>
      {dataFetched && (
        <section className="section without-padding-top">
          <div className="container">
            <div className="columns mt-3">
              <aside className="column is-one-quarter menu">
                <p className="menu-label">Índice (A-Z)</p>

                <ul className="menu-list">
                  {sections
                    .filter((section) => section._id)
                    .map((section) => (
                      <li>
                        <a
                          href={`${
                            isSharedView ? "/s" : ""
                          }/cadernos/${notebookId}/secoes/${section._id}`}
                        >
                          <span className="icon">
                            <i
                              className={`fa-solid fa-angle-${
                                currentSection &&
                                currentSection._id === section._id
                                  ? "down"
                                  : "right"
                              }`}
                            ></i>
                          </span>
                          <span>{section.title}</span>
                        </a>
                        <ul>
                          {currentSection &&
                            currentSection._id === section._id &&
                            pages
                              .filter((page) => page.sectionId === section._id)
                              .map((page) => (
                                <li>
                                  <a
                                    href={`${
                                      isSharedView ? "/s" : ""
                                    }/cadernos/${notebookId}/secoes/${
                                      section._id
                                    }/paginas/${page._id}`}
                                    className={
                                      currentPage._id === page._id &&
                                      !creatingPage &&
                                      "has-background-light has-text-dark"
                                    }
                                  >
                                    {page.title}
                                  </a>
                                </li>
                              ))}
                          {!isSharedView &&
                            currentSection &&
                            currentSection._id &&
                            currentSection._id === section._id && (
                              <li>
                                <a
                                  className={`${creatingPage &&
                                    "has-background-light has-text-dark"}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setCreatingSection(false);
                                    setCreatingPage(true);
                                    setEditingPage(false);
                                  }}
                                >
                                  <span className="icon">
                                    <i className="fa-solid fa-plus"></i>
                                  </span>
                                  <span>Nova página</span>
                                </a>
                              </li>
                            )}
                        </ul>
                      </li>
                    ))}
                  {!isSharedView && (
                    <li>
                      <a
                        className={`${creatingSection &&
                          "is-active has-text-dark"}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setEditingPage(false);
                          setCurrentPage({});
                          setCurrentSection({});
                          setCreatingPage(false);
                          setCreatingSection(true);
                        }}
                      >
                        <span className="icon">
                          <i className="fa-solid fa-plus"></i>
                        </span>
                        <span>Nova seção</span>
                      </a>
                    </li>
                  )}
                </ul>
              </aside>
              <div className="column">
                <p className="menu-label">
                  {(currentSection && currentSection.title) ||
                    (creatingSection && "Nova seção")}
                </p>
                {creatingPage || editingPage ? (
                  <div>
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          placeholder="Título da página"
                          maxLength="28"
                          value={noteTitleInput}
                          onChange={(e) =>
                            setNoteTitleInput(e.currentTarget.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="field has-addons">
                        <p className="control">
                          <button
                            className="button is-dark"
                            onClick={(e) => transformSelection(e, "bold")}
                          >
                            <span className="icon is-small">
                              <i className="fas fa-bold"></i>
                            </span>
                          </button>
                        </p>
                        <p className="control">
                          <button
                            className="button is-dark"
                            onClick={(e) => transformSelection(e, "italic")}
                          >
                            <span className="icon is-small">
                              <i className="fas fa-italic"></i>
                            </span>
                          </button>
                        </p>
                        <p className="control">
                          <button
                            className="button is-dark"
                            onClick={(e) =>
                              transformSelection(e, "strikethrough")
                            }
                          >
                            <span className="icon is-small">
                              <i className="fas fa-strikethrough"></i>
                            </span>
                          </button>
                        </p>
                        {!isTeacher(user) && (
                          <>
                            <p className="control">
                              <button
                                className="button is-primary"
                                onClick={(e) =>
                                  transformSelection(e, "highlight", "primary")
                                }
                              >
                                <span className="icon is-small">
                                  <i className="fa-solid fa-highlighter"></i>
                                </span>
                              </button>
                            </p>
                            <p className="control">
                              <button
                                className="button is-info"
                                onClick={(e) =>
                                  transformSelection(e, "highlight", "info")
                                }
                              >
                                <span className="icon is-small">
                                  <i className="fa-solid fa-highlighter"></i>
                                </span>
                              </button>
                            </p>
                            <p className="control">
                              <button
                                className="button is-warning"
                                onClick={(e) =>
                                  transformSelection(e, "highlight", "warning")
                                }
                              >
                                <span className="icon is-small">
                                  <i className="fa-solid fa-highlighter"></i>
                                </span>
                              </button>
                            </p>
                            <p className="control">
                              <button
                                className="button is-danger"
                                onClick={(e) =>
                                  transformSelection(e, "highlight", "danger")
                                }
                              >
                                <span className="icon is-small">
                                  <i className="fa-solid fa-highlighter"></i>
                                </span>
                              </button>
                            </p>
                          </>
                        )}
                      </div>
                      <div className="control">
                        <article className="message">
                          <ContentEditable
                            className="message-body"
                            html={sanitize(noteContentInput)}
                            onChange={(e) =>
                              setNoteContentInput(e.target.value)
                            }
                          />
                        </article>
                      </div>
                    </div>

                    {warningMessage && (
                      <div className="notification is-warning is-light">
                        <button
                          className="delete"
                          onClick={() => setWarningMessage("")}
                        ></button>
                        {warningMessage}
                      </div>
                    )}

                    <div className="buttons">
                      <button className="button is-dark" onClick={saveHandler}>
                        <span className="icon is-small">
                          <i className="fas fa-check"></i>
                        </span>
                        <span>Salvar</span>
                      </button>
                      <button
                        className="button"
                        onClick={() => {
                          setCreatingSection(false);
                          setCreatingPage(false);
                          setEditingPage(false);
                          setWarningMessage("");
                        }}
                      >
                        <span className="icon is-small">
                          <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>Cancelar</span>
                      </button>
                    </div>
                  </div>
                ) : currentPage && currentPage._id ? (
                  !editingPage && (
                    <div>
                      <h2 className="title is-5">{currentPage.title}</h2>
                      <article className="message">
                        <div
                          className="message-body"
                          dangerouslySetInnerHTML={{
                            __html: sanitize(
                              renderImages(renderLinks(currentPage.content))
                            ),
                          }}
                        />
                      </article>

                      <SharingModal
                        page={currentPage}
                        accessToken={token}
                        isActive={isSharingModalActive}
                        onClose={closeSharingModal}
                      />

                      {!isSharedView && (
                        <div className="buttons">
                          {isTeacher(user) && (
                            <button
                              className="button is-light"
                              onClick={openSharingModal}
                            >
                              <span className="icon is-small">
                                <i className="fa-solid fa-share-nodes"></i>
                              </span>
                              <span>
                                Compartilhar
                                {currentPage.whitelist.length > 0 &&
                                  ` (${currentPage.whitelist.length})`}
                              </span>
                            </button>
                          )}
                          <button
                            className="button is-light"
                            onClick={() => {
                              setCreatingSection(false);
                              setCreatingPage(false);
                              setEditingPage(true);
                            }}
                          >
                            <span className="icon is-small">
                              <i className="fa-solid fa-pen-to-square"></i>
                            </span>
                            <span>Editar</span>
                          </button>
                          <button
                            className="button is-light"
                            onClick={(e) => deleteHandler(e)}
                          >
                            <span className="icon is-small">
                              <i className="fa-solid fa-trash"></i>
                            </span>
                            <span>Apagar</span>
                          </button>
                        </div>
                      )}
                    </div>
                  )
                ) : (
                  !creatingSection && (
                    <article className="message is-light">
                      <div className="message-body">
                        Para começar a escrever neste caderno, basta criar uma
                        nova seção através do menu à esquerda 😉
                      </div>
                    </article>
                  )
                )}
                {creatingSection && (
                  <div>
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          placeholder="Título"
                          onChange={(e) => {
                            setCurrentSection({ title: e.currentTarget.value });
                            setNewSectionTitle(e.currentTarget.value);
                          }}
                          maxLength="28"
                        />
                      </div>
                    </div>
                    <div className="buttons">
                      <button className="button is-dark" onClick={saveSection}>
                        <span className="icon is-small">
                          <i className="fas fa-check"></i>
                        </span>
                        <span>Salvar</span>
                      </button>
                      <a className="button" href={`/cadernos/${notebookId}`}>
                        <span className="icon is-small">
                          <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>Cancelar</span>
                      </a>
                    </div>
                  </div>
                )}
                <br />
                {errorMessage && (
                  <div className="notification is-danger is-light">
                    <button
                      className="delete"
                      onClick={() => setErrorMessage("")}
                    ></button>
                    {errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  ) : (
    <div></div>
  );
}

export default Notebook;
