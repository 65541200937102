import React, { useEffect, useState } from "react";
import axios from "axios";
import * as config from "../../config";
import Header from "../Header";
import { useAuth0 } from "@auth0/auth0-react";
import { isTeacher } from "../helperFunctions";

function Decks() {
  const { isLoading, getAccessTokenSilently, user } = useAuth0();
  const [dataFetched, setDataFetched] = useState(false);

  const [decks, setDecks] = useState([]);

  const decksURL = `${config.API_BASE_URL}/decks`;

  useEffect(() => {
    const fetchData = async () => {
      setDataFetched(false);

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${config.AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        },
      });

      setDecks(
        (
          await axios.get(decksURL, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
        ).data
      );

      setDataFetched(true);
    };

    if (!isLoading) {
      fetchData().catch(() => {
        window.location = "/";
      });
    }
  }, [isLoading]);

  return (
    <div>
      <Header color="light" />
      <div className="hero is-light">
        <div className="hero-body">
          <div className="container">
            <p className="title">Flashcards</p>
          </div>
        </div>
      </div>
      <progress
        className="progress square is-small is-light"
        max="100"
        value={dataFetched ? "0" : null}
      ></progress>
      {dataFetched && (
        <div>
          <div className="section">
            <div className="container">
              <p className="title is-4">
                Meus decks{" "}
                {!isTeacher(user) &&
                  decks.length > 0 &&
                  decks
                    .map((deck) => deck.flashcardsToReview)
                    .reduce((a, b) => a + b) > 0 && (
                    <span className="tag is-dark is-rounded">
                      {decks
                        .map((deck) => deck.flashcardsToReview)
                        .reduce((a, b) => a + b)}{" "}
                      {decks
                        .map((deck) => deck.flashcardsToReview)
                        .reduce((a, b) => a + b) === 1
                        ? "card"
                        : "cards"}{" "}
                      a revisar
                    </span>
                  )}
              </p>

              {decks.length > 0 ? (
                <div className="columns is-multiline">
                  {decks.map((deck) => (
                    <aside className="column is-half">
                      <a
                        href={
                          !isTeacher(user) && deck.flashcardsToReview > 0
                            ? `/decks/${deck._id}`
                            : `/decks/${deck._id}/editar`
                        }
                      >
                        <div className={`notification is-${deck.color}`}>
                          <p className="title is-5">
                            {deck.title}{" "}
                            {!isTeacher(user) &&
                              deck.flashcardsToReview > 0 && (
                                <span className="tag is-dark is-rounded is-flashcard">
                                  {deck.flashcardsToReview}
                                </span>
                              )}
                          </p>
                        </div>
                      </a>
                    </aside>
                  ))}
                </div>
              ) : (
                <div className="notification">
                  <p className="subtitle is-6">
                    Decks criados por você aparecerão aqui
                  </p>
                </div>
              )}
              <button
                className="button is-dark is-outlined"
                onClick={() => {
                  window.location = "/decks/novo";
                }}
              >
                <span className="icon">
                  <i className="fa-solid fa-plus"></i>
                </span>
                <span>Novo deck</span>
              </button>
            </div>
          </div>
          {!isTeacher(user) && (
            <div className="section without-padding-top">
              <div className="container">
                <p className="title is-4">Compartilhados comigo</p>
                <div className="notification">
                  <p className="subtitle is-6">
                    Decks compartilhados diretamente com você aparecerão aqui
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Decks;
