import React from "react";

import Home from "./Components/Home";

import Notebooks from "./Components/Notebooks/Notebooks";
import Journal from "./Components/Notebooks/Journal";
import Notebook from "./Components/Notebooks/Notebook";
import NotebookForm from "./Components/Notebooks/NotebookForm";

import Highlights from "./Components/Highlights/Highlights";

import Decks from "./Components/Flashcards/Decks";
import Deck from "./Components/Flashcards/Deck";
import DeckForm from "./Components/Flashcards/DeckForm";

import Quizzes from "./Components/Quizzes/Quizzes";
import Quiz from "./Components/Quizzes/Quiz";
import NewQuiz from "./Components/Quizzes/NewQuiz";

import WaitingForApproval from "./Components/WaitingForApproval";
import PageNotFound from "./Components/PageNotFound";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "moment/locale/pt-br";
import "./App.css";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/cadernos",
      element: <Notebooks />,
    },
    {
      path: "/cadernos/diario/:semana?",
      element: <Journal />,
    },
    {
      path: "/s?/cadernos/:notebookId/secoes?/:sectionId?/paginas?/:pageId?",
      element: <Notebook />,
    },
    {
      path: "/cadernos/novo",
      element: <NotebookForm />,
    },
    {
      path: "/cadernos/:notebookId/editar",
      element: <NotebookForm />,
    },
    {
      path: "/marcacoes",
      element: <Highlights />,
    },
    {
      path: "/decks",
      element: <Decks />,
    },
    {
      path: "/decks/:id",
      element: <Deck />,
    },
    {
      path: "/decks/novo",
      element: <DeckForm />,
    },
    {
      path: "/decks/:deckId/editar",
      element: <DeckForm editing={true} />,
    },
    {
      path: "/questionarios",
      element: <Quizzes />,
    },
    {
      path: "/questionarios/:id",
      element: <Quiz />,
    },
    {
      path: "/questionarios/novo",
      element: <NewQuiz />,
    },
    {
      path: "/questionarios/:id/editar",
      element: <Quiz editing={true} />,
    },
    {
      path: "/aguardando",
      element: <WaitingForApproval />,
    },
    {
      path: "/*",
      element: <PageNotFound />,
    },
  ]);

  return (
    <div className="App">
      <div className="block">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
