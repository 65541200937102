import React, { useState } from "react";
import axios from "axios";
import { sanitize, addHook } from "dompurify";
import ContentEditable from "react-contenteditable";
import * as config from "../../config";
import { renderLinks, renderImages, isTeacher } from "../helperFunctions";
import { useAuth0 } from "@auth0/auth0-react";

// TODO: make this global
addHook("afterSanitizeAttributes", function(node) {
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener noreferrer nofollow");
  }
});

function JournalEntry(props) {
  const [_id, setId] = useState(props._id);
  const date = props.date.format("dddd[,] DD/MM/YYYY");
  const capitalizedDate = date[0].toUpperCase() + date.substring(1);
  const [editing, setEditing] = useState(false);
  const [deleteClickedOnce, setDeleteClickedOnce] = useState(false);
  const [content, setContent] = useState(props.content);
  const [errorMessage, setErrorMessage] = useState("");
  const [saved, setSaved] = useState(false);
  const { user } = useAuth0();

  const targetURL = `${config.API_BASE_URL}/journal-entries`;

  const saveHandler = async (e) => {
    e.preventDefault();

    if (content) {
      try {
        if (editing) {
          await axios.put(
            targetURL,
            { content, _id },
            { headers: { Authorization: `Bearer ${props.token}` } }
          );
        } else {
          const response = await axios.post(
            targetURL,
            { content },
            { headers: { Authorization: `Bearer ${props.token}` } }
          );
          setId(response.data._id);
        }
        setEditing(false);
        setSaved(true);
        setErrorMessage("");
      } catch (e) {
        setErrorMessage(
          "Não conseguimos salvar o seu registro por conta de um erro inesperado. Espere alguns segundos e tente novamente."
        );
      }
    } else {
      setErrorMessage("Você não pode salvar um registro sem conteúdo.");
    }
  };

  const deleteHandler = async (e) => {
    e.preventDefault();

    switch (e.detail) {
      case 2:
        try {
          await axios.delete(`${targetURL}/${_id}`, {
            headers: { Authorization: `Bearer ${props.token}` },
          });
          setErrorMessage("");
          window.location = "/cadernos/diario";
        } catch (e) {
          setErrorMessage(
            "Não conseguimos apagar o seu registro por conta de um erro inesperado. Espere alguns segundos e tente novamente."
          );
        }
        break;
      default:
        setDeleteClickedOnce(true);
    }
  };

  const transformSelection = (e, command, color) => {
    e.preventDefault();

    if (command === "highlight") {
      const selection = document.getSelection().toString();

      if (selection) {
        document.execCommand(
          "insertHTML",
          false,
          `<span class="tag is-${color} is-medium">${selection}</span>`
        );
      }
    } else {
      document.execCommand(command);
    }
  };

  return (props.content.length === 0 && !saved) || editing ? (
    <div className="block">
      <h2 className="title is-5">{capitalizedDate}</h2>
      <div className="field has-addons">
        <p className="control">
          <button
            className="button is-dark"
            onClick={(e) => transformSelection(e, "bold")}
          >
            <span className="icon is-small">
              <i className="fas fa-bold"></i>
            </span>
          </button>
        </p>
        <p className="control">
          <button
            className="button is-dark"
            onClick={(e) => transformSelection(e, "italic")}
          >
            <span className="icon is-small">
              <i className="fas fa-italic"></i>
            </span>
          </button>
        </p>
        <p className="control">
          <button
            className="button is-dark"
            onClick={(e) => transformSelection(e, "strikethrough")}
          >
            <span className="icon is-small">
              <i className="fas fa-strikethrough"></i>
            </span>
          </button>
        </p>
        {!isTeacher(user) && (
          <>
            <p className="control">
              <button
                className="button is-primary"
                onClick={(e) => transformSelection(e, "highlight", "primary")}
              >
                <span className="icon is-small">
                  <i className="fa-solid fa-highlighter"></i>
                </span>
              </button>
            </p>
            <p className="control">
              <button
                className="button is-info"
                onClick={(e) => transformSelection(e, "highlight", "info")}
              >
                <span className="icon is-small">
                  <i className="fa-solid fa-highlighter"></i>
                </span>
              </button>
            </p>
            <p className="control">
              <button
                className="button is-warning"
                onClick={(e) => transformSelection(e, "highlight", "warning")}
              >
                <span className="icon is-small">
                  <i className="fa-solid fa-highlighter"></i>
                </span>
              </button>
            </p>
            <p className="control">
              <button
                className="button is-danger"
                onClick={(e) => transformSelection(e, "highlight", "danger")}
              >
                <span className="icon is-small">
                  <i className="fa-solid fa-highlighter"></i>
                </span>
              </button>
            </p>
          </>
        )}
      </div>
      <div className="field">
        <article className="message">
          <ContentEditable
            className="message-body"
            html={sanitize(content)}
            onChange={(e) => setContent(e.target.value)}
          />
        </article>
      </div>
      {errorMessage && (
        <div className="field notification is-danger is-light">
          <button
            className="delete"
            onClick={() => setErrorMessage("")}
          ></button>
          {errorMessage}
        </div>
      )}
      <div className="control">
        <div className="buttons">
          <button className="button is-dark" onClick={saveHandler}>
            <span className="icon is-small">
              <i className="fas fa-check"></i>
            </span>
            <span>Salvar</span>
          </button>
          {editing && (
            <button
              className="button"
              onClick={() => {
                setContent(props.content);
                setEditing(false);
              }}
            >
              <span className="icon is-small">
                <i className="fa-solid fa-xmark"></i>
              </span>
              <span>Cancelar</span>
            </button>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="block">
      <h2 className="title is-5">{capitalizedDate}</h2>
      <article className="message">
        <div
          className="message-body"
          dangerouslySetInnerHTML={{
            __html: sanitize(renderImages(renderLinks(content))),
          }}
        />
      </article>
      <div className="control">
        <div className="buttons">
          <button className="button is-light" onClick={() => setEditing(true)}>
            <span className="icon is-small">
              <i className="fa-solid fa-pen-to-square"></i>
            </span>
            <span>Editar</span>
          </button>
          <button className="button is-light" onClick={deleteHandler}>
            <span className="icon is-small">
              <i className="fa-solid fa-trash"></i>
            </span>
            <span>
              {deleteClickedOnce ? "Clique 2x para apagar" : "Apagar"}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default JournalEntry;
