import React, { useState } from "react";

function Question(props) {
  const [title, setTitle] = useState(props.title);
  const [selectedOptions, setSelectedOptions] = useState("");

  const changeTitle = (updatedTitle) => {
    setTitle(updatedTitle);

    props.setQuestions(
      props.questions.map((question, index) =>
        index === props.index ? { ...question, text: updatedTitle } : question
      )
    );
  };

  const addOption = (e) => {
    e.preventDefault();

    if (props.options.filter((option) => option.option === "").length === 0) {
      const newOption = { option: "", correct: false };

      props.setQuestions(
        props.questions.map((question, index) =>
          index === props.index
            ? { ...question, options: [...question.options, newOption] }
            : question
        )
      );
    } else {
      props.setErrorMessage(
        "Você não pode adicionar novas alternativas a uma questão enquanto houverem alternativas em branco"
      );
    }
  };

  const toggleOptionCorrectness = (optionText) => {
    const updatedOptions = props.options.map((option) =>
      option.option === optionText
        ? { ...option, correct: !option.correct }
        : option
    );

    const updatedType =
      updatedOptions.filter((option) => option.correct).length > 1
        ? "multiple"
        : "single";

    props.setQuestions(
      props.questions.map((question, index) =>
        index === props.index
          ? { ...question, options: updatedOptions, type: updatedType }
          : question
      )
    );
  };

  const recordUserAnswer = (e) => {
    if (!props.submitted) {
      let updatedSelectedOptions;

      if (props.type === "multiple") {
        if (e.target.checked) {
          updatedSelectedOptions = (prevState) => [
            ...prevState,
            e.target.value,
          ];
        } else {
          updatedSelectedOptions = (prevState) =>
            prevState.filter((item) => item !== e.target.value);
        }
      } else {
        updatedSelectedOptions = [e.target.value];
      }

      setSelectedOptions(updatedSelectedOptions);

      props.setQuestions(
        props.questions.map((question, index) =>
          index === props.index
            ? {
                ...question,
                answered:
                  props.type === "multiple"
                    ? updatedSelectedOptions(selectedOptions).length > 0
                    : updatedSelectedOptions.length === 1,
              }
            : question
        )
      );
    }
  };

  const changeOptionTitle = (e, optionIndex) => {
    const updatedOptions = props.options.map((option, index) =>
      index === optionIndex ? { ...option, option: e.target.value } : option
    );

    const uniqueOptionTexts = [
      ...new Set(updatedOptions.map((option) => option.option)),
    ];

    if (updatedOptions.length === uniqueOptionTexts.length) {
      props.setQuestions(
        props.questions.map((question, index) =>
          index === props.index
            ? { ...question, options: updatedOptions }
            : question
        )
      );
    }
  };

  const removeQuestion = () => {
    const questionsCopy = props.questions.slice();
    questionsCopy.splice(props.index, 1);

    props.setQuestions(questionsCopy);
  };

  const sortAlphabetically = (arr) =>
    arr.sort((a, b) =>
      a.localeCompare(b, "en", {
        sensitivity: "base",
      })
    );

  const arraysIntersect = (arr1, arr2) => {
    const set1 = new Set(arr1);

    for (const item of arr2) {
      if (set1.has(item)) {
        return true;
      }
    }

    return false;
  };

  return props.editing ? (
    <div className="message">
      <div className="message-body">
        <div className="fields">
          <div className="field">
            <div className="control">
              <textarea
                className="textarea"
                rows="1"
                onChange={(e) => changeTitle(e.target.value)}
                value={title}
              />
            </div>
          </div>

          {props.options.map((option, i) => (
            <div className="field has-addons" key={option.option}>
              <p className="control">
                <button
                  className="button"
                  onClick={() => toggleOptionCorrectness(option.option)}
                >
                  {option.correct ? "✅" : "❌"}
                </button>
              </p>
              <p className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  onBlur={(e) => changeOptionTitle(e, i)}
                  defaultValue={option.option}
                />
              </p>
              <p className="control">
                <button
                  className="button"
                  onClick={() => props.removeOption(option.option)}
                >
                  <span className="icon is-small">
                    <i className="fa-solid fa-minus"></i>
                  </span>
                </button>
              </p>
            </div>
          ))}

          <div className="buttons">
            <button className="button" onClick={(e) => addOption(e)}>
              <span className="icon">
                <i className="fa-solid fa-plus"></i>
              </span>
              <span>Nova alternativa</span>
            </button>
            <button className="button" onClick={removeQuestion}>
              <span className="icon is-small">
                <i className="fa-solid fa-trash"></i>
              </span>
              <span>Apagar questão</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`message ${
        !props.submitted
          ? "is-gray"
          : JSON.stringify(
              sortAlphabetically(
                props.options
                  .filter((option) => option.correct)
                  .map((option) => option.option)
              )
            ) === JSON.stringify(sortAlphabetically(selectedOptions))
          ? "is-success"
          : arraysIntersect(
              props.options
                .filter((option) => option.correct)
                .map((option) => option.option),
              selectedOptions
            )
          ? "is-warning"
          : "is-danger"
      }`}
    >
      <div className="message-body">
        <p className="question has-text-weight-bold">{props.title}</p>
        <br />
        {props.options.map((option) => (
          <p key={option.option}>
            <label className={props.type === "multiple" ? "checkbox" : "radio"}>
              <input
                type={props.type === "multiple" ? "checkbox" : "radio"}
                name={`answer-${props.id}`}
                onChange={recordUserAnswer}
                value={option.option}
              />{" "}
              {props.submitted ? (
                option.correct ? (
                  <b>{option.option}</b>
                ) : (
                  option.option
                )
              ) : (
                option.option
              )}
            </label>
            <br />
          </p>
        ))}
      </div>
    </div>
  );
}

export default Question;
