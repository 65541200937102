import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { isTeacher } from "./helperFunctions";

function Header(props) {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const { isLoading, user, logout } = useAuth0();

  const isCurrentPath = (pathname) => {
    return window.location.pathname.includes(pathname);
  };

  const greeting = () => {
    var today = new Date();
    var hours = today.getHours();

    if (hours < 12) {
      return "Bom dia";
    } else if (hours < 18) {
      return "Boa tarde";
    } else {
      return "Boa noite";
    }
  };

  return (
    <nav
      className={`navbar is-${props.color}`}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <span className="navbar-item">
            <b>EUTIMIA</b>
          </span>
          <button
            className={`navbar-burger ${mobileMenuVisible && "is-active"}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>
        {!isLoading && (
          <div className={`navbar-menu ${mobileMenuVisible && "is-active"}`}>
            <div className="navbar-start">
              <a
                className={`navbar-item ${isCurrentPath("/cadernos") &&
                  "is-active"}`}
                href="/cadernos"
              >
                Cadernos
              </a>
              {!isTeacher(user) && (
                <a
                  className={`navbar-item ${isCurrentPath("/marcacoes") &&
                    "is-active"}`}
                  href="/marcacoes"
                >
                  Marcações
                </a>
              )}
              <a
                className={`navbar-item ${isCurrentPath("/decks") &&
                  "is-active"}`}
                href="/decks"
              >
                Flashcards
              </a>
              <a
                className={`navbar-item ${isCurrentPath("/questionarios") &&
                  "is-active"}`}
                href="/questionarios"
              >
                Questionários
              </a>
            </div>
            <div className="navbar-end">
              <div className="navbar-item is-hidden-mobile">
                {isTeacher(user) && `${greeting()}, professor(a) 👋`}
              </div>
              <div className="navbar-item">
                <button
                  className={`button is-${props.color}`}
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  Sair
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Header;
