import React, { useEffect, useState } from "react";
import axios from "axios";
import * as config from "../../config";
import Header from "../Header";
import { useAuth0 } from "@auth0/auth0-react";

function Highlights() {
  const targetURL = `${config.API_BASE_URL}/highlights`;

  const [reviewed, setReviewed] = useState(false);
  const [highlights, setHighlights] = useState([]);
  const [dueHighlights, setDueHighlights] = useState([]);

  const { isLoading, getAccessTokenSilently } = useAuth0();
  const [dataFetched, setDataFetched] = useState(false);
  const [token, setToken] = useState("");

  useEffect(() => {
    setReviewed(false);
    setHighlights([]);
    setDueHighlights([]);

    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${config.AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        },
      });
      setToken(accessToken);

      const response = await axios.get(targetURL, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setHighlights(response.data);

      const responseDue = await axios.get(`${targetURL}?due`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setDueHighlights(responseDue.data);
      setDataFetched(true);
    };

    if (!isLoading) {
      fetchData().catch(() => {
        window.location = "/";
      });
    }
  }, [isLoading, reviewed]);

  return (
    <div>
      <Header color="light" />
      <div className="hero is-light">
        <div className="hero-body">
          <div className="container">
            <p className="title">Marcações</p>
          </div>
        </div>
      </div>
      <progress
        className="progress square is-small is-light"
        max="100"
        value={dataFetched ? "0" : null}
      ></progress>
      {dataFetched && (
        <section className="section without-padding-top">
          <div className="container">
            <h2 className="title is-5">
              <span>A revisar</span>&nbsp;
              <span className="tag is-rounded is-dark">
                {dueHighlights.length}
              </span>
            </h2>
            {dueHighlights.length === 0 && (
              <article className="message is-light">
                <div className="message-body">
                  Não há nenhuma marcação para revisar no momento. Você pode
                  criar novas marcações em qualquer um de seus cadernos 🙂
                </div>
              </article>
            )}
            {dueHighlights.map((h) => (
              <article className={`message is-${h.color}`}>
                <div className="message-body">
                  <p>
                    <a className="custom" href={h.link}>
                      {h.text} <i className="fa-solid fa-link"></i>
                    </a>
                  </p>
                  <br />
                  <button
                    className={`button is-small is-${h.color}`}
                    onClick={async () => {
                      await axios.post(
                        `${targetURL}/${h._id}/review`,
                        {},
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      );
                      setReviewed(true);
                    }}
                  >
                    <span className="icon is-small">
                      <i className="fas fa-check"></i>
                    </span>
                    <span>Marcar como revisada</span>
                  </button>
                </div>
              </article>
            ))}
            {highlights.length > 0 && (
              <div>
                <h2 className="title is-5">
                  <span>Já revisadas</span>&nbsp;
                  <span className="tag is-rounded">{highlights.length}</span>
                </h2>
                {highlights.map((h) => (
                  <p className="block">
                    <span className={`tag is-${h.color} is-medium`}>
                      <a href={h.link}>
                        {h.text} <i className="fa-solid fa-link"></i>
                      </a>
                    </span>
                  </p>
                ))}
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
}

export default Highlights;
