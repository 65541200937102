import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import * as config from "../../config";
import Header from "../Header";
import { useAuth0 } from "@auth0/auth0-react";
import { sanitize, addHook } from "dompurify";
import { renderLinks, renderImages, isTeacher } from "../helperFunctions";

// TODO: make this global
addHook("afterSanitizeAttributes", function(node) {
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener noreferrer nofollow");
  }
});

function Deck() {
  const decksURL = `${config.API_BASE_URL}/decks`;
  const flashcardsURL = `${config.API_BASE_URL}/flashcards`;

  const { id } = useParams();
  const { isLoading, getAccessTokenSilently, user } = useAuth0();
  const [dataFetched, setDataFetched] = useState(false);
  const [token, setToken] = useState("");
  const [deck, setDeck] = useState({});

  const ROUNDS = 3;

  const [cardsShown, setCardsShown] = useState(0);
  const [dueCount, setDueCount] = useState(0);
  const [flashcardInReview, setFlashcardInReview] = useState({});
  const [showBackside, setShowBackside] = useState(false);
  const [flashcards, setFlashcards] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const gradeFlashcard = async (e, _id, grade) => {
    e.preventDefault();

    try {
      await axios.post(
        `${flashcardsURL}/${_id}/review`,
        { grade },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setErrorMessage("");
    } catch (e) {
      setErrorMessage(
        "Não conseguimos salvar a sua avaliação por conta de um erro inesperado. Espere alguns segundos e tente novamente."
      );
    }
  };

  const shuffle = (cards) => {
    return cards
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  };

  const showNextCard = async (e) => {
    e.preventDefault();

    if (flashcards.length > 1) {
      setShowBackside(false);
      setFlashcardInReview(flashcards[1]);
      setFlashcards(flashcards.slice(1, flashcards.length));
    }
    setCardsShown(cardsShown + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      setDataFetched(false);

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${config.AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        },
      });
      setToken(accessToken);

      setDeck(
        (
          await axios.get(`${decksURL}/${id}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
        ).data
      );

      const flashcardsData = (
        await axios.get(
          `${flashcardsURL}?deckId=${id}${isTeacher(user) ? "" : "&due"}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
      ).data;

      setFlashcards(
        flashcardsData
          .concat(shuffle(flashcardsData))
          .concat(shuffle(flashcardsData))
      );

      setDueCount(flashcardsData.length);
      setFlashcardInReview(flashcardsData[0]);

      setDataFetched(true);
    };

    if (!isLoading) {
      fetchData().catch(() => {
        window.location = "/";
      });
    }
  }, [isLoading]);

  const deleteDeck = async () => {
    try {
      await axios.delete(`${decksURL}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      window.location = `/decks`;
    } catch (e) {
      setErrorMessage(
        "Não conseguimos apagar este deck por conta de um erro inesperado. Espere alguns segundos e tente novamente."
      );
    }
  };

  return (
    <div>
      <Header color={deck.color || "light"} />
      <div className={`hero is-${deck.color || "light"}`}>
        <div className="hero-body">
          <div className="container">
            <p className="title">{deck.title || "Flashcards"}</p>
            {dataFetched && (
              <div className="mb-0">
                <a href="/decks" className={`button is-small is-${deck.color}`}>
                  <span className="icon">
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                  <span>Decks</span>
                </a>
                <a
                  href={`/decks/${deck._id}/editar`}
                  className={`button is-small is-${deck.color}`}
                >
                  <span className="icon">
                    <i className="fa-solid fa-pen-to-square"></i>
                  </span>
                  <span>Editar</span>
                </a>
                <a
                  className={`button is-small is-${deck.color}`}
                  onClick={deleteDeck}
                >
                  <span className="icon">
                    <i className="fa-solid fa-trash"></i>
                  </span>
                  <span>Apagar</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <progress
        className="progress square is-small is-light"
        max="100"
        value={dataFetched ? "0" : null}
      ></progress>
      {dataFetched && (
        <section className="section without-padding-top">
          <div className="container mt-5">
            {isTeacher(user) && (
              <div className="notification is-warning is-light">
                Esta é apenas uma prévia - extremamente limitada - da
                experiência de seus alunos. A ordem e a quantidade de flashcards
                apresentados a eles muda significativamente a cada dia, graças
                ao nosso algoritmo de revisão inteligente. Você, como
                professor(a), verá sempre o mesmo conteúdo.
              </div>
            )}
            <h2 className="title is-5">Você lembra do verso deste cartão? </h2>
            <progress
              className="progress is-small"
              value={flashcards.length === 0 ? 100 : cardsShown}
              max={dueCount * ROUNDS}
            ></progress>
            {deck.flashcardsTotal > 0 && cardsShown < dueCount * ROUNDS ? (
              <div>
                <article className="message is-dark is-medium">
                  <div className="message-body">
                    <p
                      className="block"
                      dangerouslySetInnerHTML={{
                        __html: sanitize(
                          renderImages(renderLinks(flashcardInReview.front))
                        ),
                      }}
                    ></p>
                    {showBackside ? (
                      <div>
                        <hr />
                        <p
                          className="block"
                          dangerouslySetInnerHTML={{
                            __html: sanitize(
                              renderImages(renderLinks(flashcardInReview.back))
                            ),
                          }}
                        ></p>
                      </div>
                    ) : (
                      <button
                        className="button is-dark"
                        onClick={() => setShowBackside(true)}
                      >
                        <span className="icon">
                          <i className="fa-solid fa-eye"></i>
                        </span>
                        <span>Revelar</span>
                      </button>
                    )}
                  </div>
                </article>
                {showBackside && cardsShown < dueCount * (ROUNDS - 1) && (
                  <div className="buttons block">
                    <button
                      className="button is-success"
                      onClick={(e) => {
                        setShowBackside(false);
                        showNextCard(e);
                      }}
                    >
                      Eu lembrava
                    </button>
                    <button
                      className="button is-danger"
                      onClick={(e) => {
                        setShowBackside(false);
                        showNextCard(e);
                      }}
                    >
                      Não lembrava
                    </button>
                  </div>
                )}
                {showBackside && cardsShown >= dueCount * (ROUNDS - 1) && (
                  <div className="block">
                    <h3 className="subtitle is-6 block">
                      Depois de ver este card {ROUNDS} vezes, o quão confiante
                      você está se sentindo em relação a ele?
                    </h3>
                    <div className="field has-addons">
                      {["😱", "😰", "😨", "🤨", "😅", "😎"].map(
                        (emoji, index) => (
                          <p className="control">
                            <button
                              className="button is-medium"
                              onClick={async (e) => {
                                if (!isTeacher(user)) {
                                  await gradeFlashcard(
                                    e,
                                    flashcardInReview._id,
                                    index
                                  );
                                }
                                showNextCard(e);
                              }}
                            >
                              {emoji}
                            </button>
                          </p>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <p className="block">
                  <strong>Parabéns!</strong> Você revisou todos os cards que
                  estavam pendentes neste deck 👏
                </p>
                <button
                  className="button"
                  onClick={() => {
                    window.location = isTeacher(user)
                      ? `/decks/${id}/editar`
                      : "/decks";
                  }}
                >
                  <span className="icon">
                    <i className="fa-solid fa-flag-checkered"></i>
                  </span>
                  <span>Encerrar revisão</span>
                </button>
              </div>
            )}

            {errorMessage && (
              <div className="notification is-danger is-light mt-5">
                <button
                  className="delete"
                  onClick={() => setErrorMessage("")}
                ></button>
                {errorMessage}
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
}

export default Deck;
