import React from "react";

function PageNotFound() {
  return (
    <div>
      <nav
        className="navbar is-white"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <span className="navbar-item">
              <b>EUTIMIA</b>
            </span>
          </div>
        </div>
      </nav>
      <div className="hero is-fullheight-with-navbar">
        <div className="hero-body">
          <div className="container">
            <img src={`${process.env.PUBLIC_URL}/assets/images/travolta.gif`} />
            <p className="title">Página não encontrada</p>
            <p className="subtitle">
              Você tem certeza de que está no lugar certo? Se sim, por favor
              entre em contato conosco através do email{" "}
              <b>contato@eutimia.cc</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
