import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "../Header";
import * as config from "../../config";
import axios from "axios";
import { isTeacher } from "../helperFunctions";

function Notebooks() {
  const { isLoading, getAccessTokenSilently, user } = useAuth0();
  const [dataFetched, setDataFetched] = useState(false);
  const [ownedNotebooks, setOwnedNotebooks] = useState([]);
  const [sharedNotebooks, setSharedNotebooks] = useState([]);

  const notebooksURL = `${config.API_BASE_URL}/notebooks`;

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${config.AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        },
      });

      setOwnedNotebooks(
        (
          await axios.get(notebooksURL, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
        ).data
      );

      setSharedNotebooks(
        (
          await axios.get(`${notebooksURL}?shared`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
        ).data
      );

      setDataFetched(true);
    };

    if (!isLoading) {
      fetchData().catch(() => {
        window.location = "/";
      });
    }
  }, [isLoading]);
  return (
    <div>
      <Header color="light" />
      <div className="hero is-light">
        <div className="hero-body">
          <div className="container">
            <p className="title">Cadernos</p>
          </div>
        </div>
      </div>
      <progress
        className="progress square is-small is-light"
        max="100"
        value={dataFetched ? "0" : null}
      ></progress>
      {dataFetched && (
        <div>
          <div className="section">
            <div className="container">
              <p className="title is-4">Meus cadernos</p>
              <div className="columns is-multiline">
                <aside className="column is-half">
                  <a href="/cadernos/diario">
                    <div className="notification is-dark">
                      <p className="title is-5">📆 Diário</p>
                    </div>
                  </a>
                </aside>
                {ownedNotebooks.map((notebook) => (
                  <aside className="column is-half">
                    <a href={`/cadernos/${notebook._id}`}>
                      <div className={`notification is-${notebook.color}`}>
                        <p className="title is-5">{notebook.title}</p>
                      </div>
                    </a>
                  </aside>
                ))}
              </div>
              <a href="/cadernos/novo" className="button is-dark is-outlined">
                <span className="icon">
                  <i className="fa-solid fa-plus"></i>
                </span>
                <span>Novo caderno</span>
              </a>
            </div>
          </div>
          {!isTeacher(user) && (
            <div className="section without-padding-top">
              <div className="container">
                <p className="title is-4">Compartilhados comigo</p>

                <div className="columns is-multiline">
                  {sharedNotebooks.map((notebook) => (
                    <aside className="column is-half">
                      <a href={`/s/cadernos/${notebook._id}`}>
                        <div className={`notification is-${notebook.color}`}>
                          <p className="title is-5">{notebook.title}</p>
                        </div>
                      </a>
                    </aside>
                  ))}
                </div>

                {sharedNotebooks.length === 0 && (
                  <div className="notification">
                    <p className="subtitle is-6">
                      Cadernos compartilhados diretamente com você aparecerão
                      aqui
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Notebooks;
