import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import * as config from "../../config";
import Header from "../Header";
import { useParams } from "react-router-dom";

function NotebookForm() {
  const [color, setColor] = useState("");
  const [title, setTitle] = useState("");
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const [dataFetched, setDataFetched] = useState(false);
  const [token, setToken] = useState("");
  const targetURL = `${config.API_BASE_URL}/notebooks`;
  const [errorMessage, setErrorMessage] = useState("");
  const { notebookId } = useParams();
  const [_, setNotebook] = useState({});

  const notebooksURL = `${config.API_BASE_URL}/notebooks`;

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${config.AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        },
      });
      setToken(accessToken);

      if (notebookId) {
        const notebookData = (
          await axios.get(`${notebooksURL}/${notebookId}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
        ).data;

        setNotebook(notebookData);
        setTitle(notebookData.title);
        setColor(notebookData.color);
      }
      setDataFetched(true);
    };

    fetchData();
  }, [isLoading]);

  const saveHandler = async (e) => {
    e.preventDefault();

    if (color && title) {
      try {
        if (notebookId) {
          await axios.put(
            `${targetURL}/${notebookId}`,
            {
              color,
              title,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          window.location = `/cadernos/${notebookId}`;
        } else {
          await axios.post(
            targetURL,
            {
              color,
              title,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          window.location = "/cadernos";
        }
      } catch (e) {
        setErrorMessage(
          "Não conseguimos salvar o seu caderno por conta de um erro inesperado. Espere alguns segundos e tente novamente."
        );
      }
    } else {
      setErrorMessage(
        "Você precisa preencher todos os campos acima para salvar este caderno."
      );
    }
  };

  return (
    <div>
      <Header color={notebookId || color ? color : "light"} />
      <div className={`hero is-${notebookId || color ? color : "light"}`}>
        <div className="hero-body">
          <div className="container">
            <p className="title">
              {notebookId || title ? title : "Novo caderno"}
            </p>
          </div>
        </div>
      </div>
      <progress
        className={`progress square is-small is-${
          notebookId || color ? color : "light"
        }`}
        max="100"
        value={dataFetched ? "0" : null}
      ></progress>
      {dataFetched && (
        <section className="section">
          <div className="container">
            <h2 className="title is-4">Propriedades</h2>
            <div className="fields">
              <div className="field">
                <label className="label">Cor</label>
                <div className="control">
                  <div className="buttons">
                    <button
                      className={`button is-info ${color !== "info" &&
                        "is-light"}`}
                      onClick={() => setColor("info")}
                    >
                      <span className="icon is-small">
                        <i
                          className={`fa-regular ${
                            color === "info" ? "fa-circle-dot" : "fa-circle"
                          }`}
                        ></i>
                      </span>
                      <span>Azul</span>
                    </button>
                    <button
                      className={`button is-success ${color !== "success" &&
                        "is-light"}`}
                      onClick={() => setColor("success")}
                    >
                      <span className="icon is-small">
                        <i
                          className={`fa-regular ${
                            color === "success" ? "fa-circle-dot" : "fa-circle"
                          }`}
                        ></i>
                      </span>
                      <span>Verde</span>
                    </button>
                    <button
                      className={`button is-warning ${color !== "warning" &&
                        "is-light"}`}
                      onClick={() => setColor("warning")}
                    >
                      <span className="icon is-small">
                        <i
                          className={`fa-regular ${
                            color === "warning" ? "fa-circle-dot" : "fa-circle"
                          }`}
                        ></i>
                      </span>
                      <span>Amarelo</span>
                    </button>
                    <button
                      className={`button is-danger ${color !== "danger" &&
                        "is-light"}`}
                      onClick={() => setColor("danger")}
                    >
                      <span className="icon is-small">
                        <i
                          className={`fa-regular ${
                            color === "danger" ? "fa-circle-dot" : "fa-circle"
                          }`}
                        ></i>
                      </span>
                      <span>Vermelho</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Título</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    onChange={(e) => setTitle(e.currentTarget.value)}
                    maxLength="22"
                    value={title}
                  />
                </div>
              </div>
              {errorMessage && (
                <div className="field notification is-danger is-light">
                  <button
                    className="delete"
                    onClick={() => setErrorMessage("")}
                  ></button>
                  {errorMessage}
                </div>
              )}
              <div className="buttons">
                <button className="button is-dark" onClick={saveHandler}>
                  <span className="icon is-small">
                    <i className="fas fa-check"></i>
                  </span>
                  <span>Salvar</span>
                </button>
                <button
                  className="button"
                  onClick={() =>
                    (window.location = notebookId
                      ? `/cadernos/${notebookId}`
                      : `/cadernos`)
                  }
                >
                  <span className="icon is-small">
                    <i className="fa-solid fa-xmark"></i>
                  </span>
                  <span>Cancelar</span>
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default NotebookForm;
