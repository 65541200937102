import React from "react";

function PageNotFound() {
  return (
    <div>
      <nav
        className="navbar is-white"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <span className="navbar-item">
              <b>EUTIMIA</b>
            </span>
          </div>
        </div>
      </nav>
      <div className="hero is-fullheight-with-navbar">
        <div className="hero-body">
          <div className="container">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/happy.gif`}
              width="150"
            />
            <br />
            <br />
            <p className="title">Estamos quase lá...</p>
            <p className="subtitle">
              Nossa equipe irá revisar seu cadastro e aprovar seu acesso o
              quanto antes possível.
              <br />
              <br />
              <b>Você receberá um e-mail</b> assim que tomarmos uma decisão 😉
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
