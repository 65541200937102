import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Header from "../Header";
import * as config from "../../config";
import { isTeacher } from "../helperFunctions";

function Quizzes() {
  const { isLoading, getAccessTokenSilently, user } = useAuth0();
  const [dataFetched, setDataFetched] = useState(false);
  const [quizzes, setQuizzes] = useState([]);

  const targetURL = `${config.QUIZZES_API_BASE_URL}/quiz/`;

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${config.AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        },
      });

      const response = await axios.get(targetURL, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setQuizzes(response.data);

      setDataFetched(true);
    };

    if (!isLoading) {
      fetchData().catch((e) => {
        console.log(e);
      });
    }
  }, [isLoading]);

  return (
    <div>
      <Header color="light" />
      <div className="hero is-light">
        <div className="hero-body">
          <div className="container">
            <p className="title">Questionários</p>
          </div>
        </div>
      </div>
      <progress
        className="progress square is-small is-light"
        max="100"
        value={dataFetched ? "0" : null}
      ></progress>
      {dataFetched && (
        <div>
          <div className="section">
            <div className="container">
              <p className="title is-4">Meus questionários</p>
              {quizzes.length > 0 ? (
                <div className="columns is-multiline">
                  {quizzes.map((quiz) => (
                    <aside className="column is-half">
                      <a
                        href={`/questionarios/${quiz.id}${
                          isTeacher(user) || quiz.question_ids.length === 0
                            ? "/editar"
                            : ""
                        }`}
                      >
                        <div className={`notification is-${quiz.color}`}>
                          <p className="title is-5">{quiz.title}</p>
                        </div>
                      </a>
                    </aside>
                  ))}
                </div>
              ) : (
                <div className="notification">
                  <p className="subtitle is-6">
                    Questionários compartilhados diretamente com você aparecerão
                    aqui
                  </p>
                </div>
              )}

              <button
                className="button is-dark is-outlined"
                onClick={() => {
                  window.location = "/questionarios/novo";
                }}
              >
                <span className="icon">
                  <i className="fa-solid fa-plus"></i>
                </span>
                <span>Novo questionário</span>
              </button>
            </div>
          </div>
          {!isTeacher(user) && (
            <div className="section without-padding-top">
              <div className="container">
                <p className="title is-4">Compartilhados comigo</p>
                <div className="notification">
                  <p className="subtitle is-6">
                    Questionários compartilhados diretamente com você aparecerão
                    aqui
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Quizzes;
